import React from 'react';

import Layout from '../templates/Layout';

import Seo from '../components/seo/Seo';
import FaqItem from '../components/pages-list-items/FaqItem';

import { generateUniqueId } from '../utils/utils';

import * as S from '../styles/pagesStyles';

import FAQ from '../data/faq';

const FAQPage = () => (
    <Layout path="faq">
        <Seo title="FAQ" />

        <S.TitleLong>Frequently Asked Questions</S.TitleLong>
        <S.BottomBorder />

        <S.Content>
            <S.QuestionBox>
                {FAQ.map((item, i) => (
                    <FaqItem key={generateUniqueId()} id={i + 1} question={item.question} />
                ))}
            </S.QuestionBox>

            <S.HR />

            <S.QuestionBox>
                {FAQ.map((item, i) => (
                    <FaqItem
                        key={generateUniqueId()}
                        id={i + 1}
                        question={item.question}
                        answer={item.answer}
                    />
                ))}
            </S.QuestionBox>
        </S.Content>
    </Layout>
);

export default FAQPage;
