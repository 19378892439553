import React from 'react';
import axios from 'axios';

import Button from '../button/Button';
import ReCAPTCHA from 'react-google-recaptcha';

import { getBasePath } from '../../utils/utils';

import * as S from './contactFormStyles';

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messageSent: false,
            error: null,
            firstName: '',
            lastName: '',
            email: '',
            message: '',
            csrf: null,
        };

        this.reRef = React.createRef();
    }

    componentDidMount = () => {
        axios(`${getBasePath()}api/get-csrf/`)
            .then(res => {
                if (res.data.success) {
                    this.setState({ csrf: res.data.token });
                }
            })
            .catch(() => {});
    };

    setName = e => {
        this.setState({ firstName: e.target.value });
    };

    setLastName = e => {
        this.setState({ lastName: e.target.value });
    };

    setEmail = e => {
        this.setState({ email: e.target.value });
    };

    setMessage = e => {
        this.setState({ message: e.target.value });
    };

    sendMessage = async e => {
        e.preventDefault();
        const { firstName, lastName, email, message, csrf } = this.state;
        const token = await this.reRef.current.executeAsync();

        axios({
            method: 'POST',
            url: `${getBasePath()}send-contact-message/`,
            data: { firstName, lastName, email, message, token, csrf },
            headers: { 'Content-type': 'application/json' },
        })
            .then(res => {
                if (res.data.success) {
                    this.clearForm();
                    this.setState({ messageSent: true });
                } else
                    this.setState({
                        error: 'An error occured when sending a message. Please try again.',
                    });
            })
            .catch(() =>
                this.setState({
                    error: 'An error occured when sending a message. Please try again.',
                })
            );
    };

    clearForm = () => {
        this.setState({ firstName: '', lastName: '', email: '', message: '', error: null });
    };

    getNewRecaptchaValues = () => {
        if (this.state.siteKey === 'none' || !this.state.csrf) {
            axios(`${getBasePath()}api/get-csrf/`)
                .then(res => {
                    if (res.data.success) {
                        this.setState({ csrf: res.data.token, siteKey: res.data.key });
                    }
                })
                .catch(() => {});
        }
    };

    resetReCaptcha = () => {
        this.reRef.current.reset();
    };

    render() {
        return this.state.messageSent ? (
            <React.Fragment>
                <S.Message>Your message was sent successfully!</S.Message>
            </React.Fragment>
        ) : (
            <S.ContactForm>
                <S.Row>
                    <S.RowCol>
                        <label htmlFor="firstName">
                            First Name <span>*</span>
                        </label>
                    </S.RowCol>
                    <S.RowCol>
                        <label htmlFor="lastName">Last Name</label>
                    </S.RowCol>
                </S.Row>

                <S.Row>
                    <S.RowCol>
                        <input
                            type="text"
                            name="firstName"
                            maxLength="50"
                            value={this.state.firstName}
                            onChange={this.setName}
                        />
                    </S.RowCol>
                    <S.RowCol>
                        <input
                            type="text"
                            name="lastName"
                            maxLength="50"
                            value={this.state.lastName}
                            onChange={this.setLastName}
                        />
                    </S.RowCol>
                </S.Row>

                <S.Row>
                    <label htmlFor="email">
                        Email Address <span>*</span>
                    </label>
                </S.Row>
                <S.Row>
                    <input
                        type="email"
                        name="email"
                        maxLength="50"
                        value={this.state.email}
                        onChange={this.setEmail}
                    />
                </S.Row>

                <S.Row>
                    <label htmlFor="message">
                        Message <span>*</span>
                    </label>
                </S.Row>
                <S.Row>
                    <textarea
                        name="message"
                        value={this.state.message}
                        onChange={this.setMessage}
                    ></textarea>
                </S.Row>

                <S.ErrorMsg>{this.state.error}</S.ErrorMsg>

                <ReCAPTCHA
                    ref={this.reRef}
                    sitekey={process.env.REACT_APP_RE_RAKTAS}
                    size="invisible"
                    onChange={this.resetReCaptcha}
                />

                <S.Row>
                    <Button
                        disabled={!(this.state.firstName && this.state.email && this.state.message)}
                        text="Send"
                        onClick={this.sendMessage}
                    />
                </S.Row>
            </S.ContactForm>
        );
    }
}

export default ContactForm;
