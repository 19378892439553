import React from 'react';

import BlackjackRules from '../../components/game-rules/BlackjackRules';
import GameRules from '../../templates/GameRules';

const BlackjackRulesPage = () => (
    <GameRules
        gameTitle="Blackjack"
        gameRules={<BlackjackRules />}
        //
    />
);

export default BlackjackRulesPage;
