import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as S from './footerStyles';

const FooterMenuItem = ({ text, path, active }) =>
    active ? (
        <S.MenuItem active={active}>{text}</S.MenuItem>
    ) : (
        <Link to={path}>
            <S.MenuItem>{text}</S.MenuItem>
        </Link>
    );

FooterMenuItem.propTypes = {
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
};

export default FooterMenuItem;
