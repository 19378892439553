import styled, { css } from 'styled-components';

import screen from '../../styles/mediaScreens';

export const Footer = styled.footer`
    background-image: linear-gradient(
        to right bottom,
        var(--color-header-dark-blue),
        var(--color-header-mid-blue),
        var(--color-header-light-blue)
    );
    background-blend-mode: overlay;
    border-top: 1px solid var(--color-white-tinted-dark);
    border-bottom: none;
    margin: 0 auto;

    height: 6rem;

    display: flex;
    justify-content: center;

    @media ${screen.phonePort} {
        height: 5rem;
        padding-top: 0;
    }
`;

export const FooterBox = styled.div`
    display: flex;
    flex-direction: column;
    color: var(--color-footer-menu-item);
    display: flex;
    justify-content: center;

    a {
        margin-left: 0.25rem;
        color: var(--color-footer-menu-item);

        &:hover {
            color: var(--color-link-highlight);
        }
    }
`;

// ------------------------------------------

export const Separator = styled.span`
    color: var(--color-disabled-text);
    margin-right: 1.5rem;
    margin-left: 1.75rem;
    font-size: 1.2rem;
`;

export const NavBar = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--color-disabled-text);
    width: 35rem;

    @media ${screen.phonePort} {
        width: 90vw;
        margin-bottom: 0.5rem;
    }
`;

const activeInactiveStyles = ({ active }) => {
    if (active)
        return css`
            color: var(--color-link-highlight);

            &:hover {
                cursor: default;
            }
        `;
    else
        return css`
            &:hover {
                cursor: pointer;
                color: var(--color-link-highlight);
            }
        `;
};

export const MenuItem = styled.p`
    font-size: 1.2rem;
    text-transform: uppercase;

    ${activeInactiveStyles}

    @media ${screen.phonePort} {
        font-size: 1.1rem;
    }
`;

// ------------------------------------------

export const Copyright = styled.div`
    display: flex;
    justify-content: center;
    font-family: var(--font-family-prosto);
    font-size: 1.15rem;
    margin-top: -0.25rem;
    color: var(--color-white-tinted-dark);

    @media ${screen.phonePort} {
        font-size: 1.05rem;
        margin-top: -0.125rem;
    }
`;
