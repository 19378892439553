import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import BlackjackRulesPage from './BlackjackRulesPage';
import JackChangeItRulesPage from './JackChangeItRulesPage';
import CrazyEightsRulesPage from './CrazyEightsRulesPage';

const AllGameRulesPages = () => (
    <Fragment>
        <Route path="/rules/blackjack" component={BlackjackRulesPage} />
        <Route path="/rules/jack-change-it" component={JackChangeItRulesPage} />
        <Route path="/rules/crazy-eights" component={CrazyEightsRulesPage} />
    </Fragment>
);

export default AllGameRulesPages;
