import React from 'react';

import Layout from '../templates/Layout';

import Seo from '../components/seo/Seo';
import ContactForm from '../components/contact-form/ContactForm';

import * as S from '../styles/pagesStyles';

const ContactPage = () => (
    <Layout path="contact">
        <Seo title="Contact Us" />

        <S.Title>Contact Us</S.Title>

        <S.Content>
            <p style={{ textAlign: 'center' }}>
                If you have any questions or queries, a member of staff will be happy to help.
                Please email us using the form below, and we will be sure to get back to you as soon
                as possible.
            </p>

            <ContactForm />
        </S.Content>
    </Layout>
);

export default ContactPage;
