import React from 'react';
import PropTypes from 'prop-types';

import { getBasePath } from '../../utils/utils';

import * as S from './pagesListItemStyles';

export const GameThumbnail = ({ gameName, comingSoon }) => {
    const image = <img src={`${getBasePath()}static/img/home/${gameName}.jpg`} alt={gameName} />;

    return (
        <S.Figure comingSoon={comingSoon}>
            {!comingSoon ? <a href={`${getBasePath()}${gameName}`}>{image}</a> : image}
        </S.Figure>
    );
};

GameThumbnail.propTypes = {
    gameName: PropTypes.string.isRequired,
    comingSoon: PropTypes.bool,
};

export default GameThumbnail;
