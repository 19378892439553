import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';

import * as S from './pagesListItemStyles';

const FaqItem = ({ id, question, answer }) =>
    answer ? (
        <Fragment>
            <S.FaqItemBox id={id}>
                <S.BulletPoint>◆</S.BulletPoint>
                <S.Question>{question}</S.Question>
            </S.FaqItemBox>

            <S.Answer>{answer}</S.Answer>
        </Fragment>
    ) : (
        <Fragment>
            <S.FaqItemBox>
                <S.BulletPoint>◆</S.BulletPoint>
                <S.Question>
                    <Link to={`/faq#${id}`} style={{ color: '#e2e3e5' }}>
                        {question}
                    </Link>
                </S.Question>
            </S.FaqItemBox>
        </Fragment>
    );

FaqItem.propTypes = {
    id: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    answer: PropTypes.string,
};

export default FaqItem;
