import React from 'react';
import PropTypes from 'prop-types';

import * as S from './headerStyles';

const MenuIcon = ({ onClick }) => (
    <S.Icon viewBox="0 0 32 32" onClick={onClick}>
        <path d="M4 17.333h24c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-24c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333zM4 9.333h24c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-24c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333zM4 25.333h24c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-24c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333z"></path>
    </S.Icon>
);

MenuIcon.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default MenuIcon;
