import React from 'react';
import { Link } from 'react-router-dom';

import Layout from '../templates/Layout';

import Seo from '../components/seo/Seo';

import * as S from '../styles/pagesStyles';

const AboutPage = () => (
    <Layout path="about">
        <Seo title="About Us" />

        <S.Title>About Us</S.Title>

        <S.Content>
            <p>Hello and welcome to Proper Card Games!</p>

            <p>
                Our website is focused on classic card games, and our goal is to make great versions
                of the games you already know and love in real life.
            </p>

            <p>
                All our games are free and accessible without needing to download or sign up. As
                long as you have an Internet connection, you can play them anywhere on your
                computer, smart phone or table device.
            </p>

            {/* <p>
                Most of our games have both singleplayer and multiplayer modes, and are fully
                customizable. Check our
                <Link to="/faq"> FAQ </Link> page for more details.
            </p> */}

            <p>
                We try very hard to make the games simple and easy to use, and we hope you enjoy
                playing them as much as we enjoy making them.
            </p>

            <p>
                Have something to suggest or want to get in touch? Email us by visiting our
                <Link to="/contact"> contact </Link>
                page.
            </p>

            <p>
                Find out more about our privacy policy
                <Link to="/privacy"> here</Link>.
            </p>

            <h4>The Proper Card Games Team</h4>
        </S.Content>
    </Layout>
);

export default AboutPage;
