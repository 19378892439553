import styled from 'styled-components';

import screen from '../../styles/mediaScreens';

export const Header = styled.header`
    height: 10rem;
    background-image: linear-gradient(
        to right bottom,
        var(--color-header-dark-blue),
        var(--color-header-mid-blue),
        var(--color-header-light-blue)
    );

    box-shadow: var(--shadow-main);

    position: relative;
    z-index: 10;

    border-bottom: 1px solid var(--color-white-tinted-dark);
    margin: 0 auto;

    @media ${screen.tabletPort} {
        height: 8rem;
    }

    @media ${screen.phoneLand} {
        height: 6rem;
    }
`;

export const HeaderBox = styled.div`
    margin: auto;
    display: flex;
    position: relative;
    max-width: 114rem;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 8rem;

    @media ${screen.tabletPort} {
        justify-content: space-between;
        padding: 0 4rem;
    }

    @media ${screen.phonePort} {
        padding: 0 2rem;
    }

    @media ${screen.phonePort5c} {
        padding: 0 1rem;
    }
`;

export const Logo = styled.img`
    height: 7rem;
    margin-top: 0.25rem;

    @media ${screen.tabletPort} {
        height: 6rem;
    }

    @media ${screen.phoneLand} {
        height: 5rem;
    }
`;

export const Title = styled.div`
    font-family: var(--font-family-prosto);
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 3.5rem;
    width: 100%;
    padding: 0 1rem;

    &:hover {
        cursor: default;
    }

    @media ${screen.tabletPort} {
        width: unset;
        margin-left: unset;
        text-align: center;
        margin-top: 0.5rem;
    }

    @media ${screen.phoneLand} {
        font-size: 2.5rem;
    }

    @media ${screen.phonePort} {
        font-size: 2.2rem;
    }

    @media ${screen.phonePort5c} {
        font-size: 2rem;
    }
`;

export const Icon = styled.svg`
    display: none;

    @media ${screen.tabletPort} {
        display: flex;
        margin-top: 0.5rem;
        height: 5rem;
        fill: var(--color-white-text);

        &:hover {
            fill: var(--color-link-highlight);
            cursor: pointer;
        }
    }

    @media ${screen.phoneLand} {
        height: 4.5rem;
        margin-top: 0.25rem;
    }
`;
