import React from 'react';
import PropTypes from 'prop-types';

import NavBarItem from './NavBarItem';

import * as S from './navBarStyles';

const NavBar = ({ path }) => (
    <S.NavBar>
        <NavBarItem text="Games" path="/" active={path === 'home'} />
        <S.Separator>/</S.Separator>

        <NavBarItem text="About" path="/about" active={path === 'about'} />
        <S.Separator>/</S.Separator>

        <NavBarItem text="Rules" path="/rules" active={path === 'rules'} />
    </S.NavBar>
);

NavBar.propTypes = {
    path: PropTypes.string,
};

export default NavBar;
