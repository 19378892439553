import styled, { css } from 'styled-components';

import screen from '../../styles/mediaScreens';

export const NavBar = styled.nav`
    display: flex;
    margin-top: 1.75rem;

    @media ${screen.minWidth} {
        right: 1.5rem;
    }

    @media ${screen.tabletPort} {
        display: none;
    }
`;

const activeInactiveStyles = ({ active }) => {
    if (active)
        return css`
            color: var(--color-link-highlight);

            &:hover {
                cursor: default;
            }
        `;
    else
        return css`
            &:hover {
                cursor: pointer;
                color: var(--color-link-highlight);
            }
        `;
};

export const NavBarItem = styled.div`
    margin-right: 2rem;
    font-size: 1.3rem;
    text-transform: uppercase;

    ${activeInactiveStyles}

    @media ${screen.minWidth} {
        margin-right: 1.5rem;
    }
`;

export const Separator = styled.span`
    color: var(--color-button-beige);
    margin-right: 2rem;
    font-size: 1.2rem;

    @media ${screen.minWidth} {
        margin-right: 1rem;
    }
`;
