import React from 'react';

import { getBasePath } from '../../utils/utils.js';

import * as S from './rulesStyles';

const PATH = getBasePath() + 'static/img/cards';

const JackChangeItRules = () => (
    <S.Content>
        <p>
            Jack Change It is a simple shedding-type game of the Crazy Eights family, and it's
            usually played by two or more players. It takes the basic gameplay mechanic of Crazy
            Eights and extends it by adding special abilities to the other cards in the game.
        </p>

        <h1>Game Objective</h1>

        <p>The purpose of the game is to discard all of your cards before your opponents.</p>

        <h1>Setup</h1>
        <p>
            Using a standard deck of 52 cards, seven cards are dealt to each player to create their
            hands. The remaining cards form the "deck", which players will use to draw their new
            cards. The top card is turned face up beside the deck to form the "pile".
        </p>

        <h1>Game Play</h1>
        <p>
            The player to the left of the dealer starts first, choosing a card that matches either
            the Suit or the Rank of the card on the pile. If the player cannot play a card on this
            turn, they draw a card from the deck, and then the turn passes to the left.
        </p>

        <p>
            When there are no more cards left in the deck, the top pile card is removed and placed
            to the side. The remaining cards are reshuffled to form a new deck, and the game
            resumes.
        </p>

        <h1>Trick Cards</h1>
        <p>
            Several ranks of cards have specific effects when they are played, and these are
            normally referred to as "trick cards".
        </p>

        <p>
            A player cannot finish on any of these cards, except in the case of Queens, which can be
            used to finish when only two players are participating. If they have one card remaining,
            and it is a trick card, then the player will have to draw a card from the deck.
        </p>

        <S.Table>
            <S.TableRow>
                <S.TableRowCardCol1>
                    <img src={`${PATH}/2C.png`} alt="2C" />
                </S.TableRowCardCol1>
                <S.TableRowCardCol2>
                    <p>
                        When a 2 is played, the next player must draw two cards from the deck.
                        However, if they possess a 2 themselves, they may play it instead, and the
                        next player after them must draw four cards (two for each 2 played).
                        <br />
                        <br />
                        This can continue until all the 2s are played, and a player has to draw
                        eight cards.
                    </p>
                </S.TableRowCardCol2>
            </S.TableRow>

            <S.TableRow>
                <S.TableRowCardCol1>
                    <img src={`${PATH}/AH.png`} alt="AH" />
                </S.TableRowCardCol1>
                <S.TableRowCardCol2>
                    <p>
                        When the Ace of Hearts is played, the next player must draw five cards from
                        the deck.
                        <br />
                        <br />
                        This cannot be passed on to another player but can be avoided by putting
                        forward a 5 of Hearts.
                    </p>
                </S.TableRowCardCol2>
            </S.TableRow>

            <S.TableRow>
                <S.TableRowCardCol1>
                    <img src={`${PATH}/8D.png`} alt="8D" />
                </S.TableRowCardCol1>
                <S.TableRowCardCol2>
                    <p>
                        When an 8 is played, the player whose turn it is next misses their turn, and
                        play passes to the next player.
                        <br />
                        <br />
                        In a two-player game, after an 8 is played, the player can play another card
                        with a matching Suit or Rank straight away.
                    </p>
                </S.TableRowCardCol2>
            </S.TableRow>

            <S.TableRow>
                <S.TableRowCardCol1>
                    <img src={`${PATH}/JS.png`} alt="JS" />
                </S.TableRowCardCol1>
                <S.TableRowCardCol2>
                    <p>
                        When a Jack is played, the player calls "Jack change it to..." and names one
                        of the other three Suits.The next player must match that Suit or play
                        another Jack.
                        <br />
                        <br />
                        If they can't play that card, they have to draw a card from the deck. This
                        continues until the card is played.
                    </p>
                </S.TableRowCardCol2>
            </S.TableRow>

            <S.TableRow>
                <S.TableRowCardCol1>
                    <img src={`${PATH}/QC.png`} alt="QC" />
                </S.TableRowCardCol1>
                <S.TableRowCardCol2>
                    <p>
                        When a Queen is played, the order of play is reversed. The player to the
                        right will take the next turn, and play continues in this fashion until
                        another Queen is played.
                        <br />
                        <br />
                        In a two-player game, the Queen's effect is ignored as play's order cannot
                        be changed.
                    </p>
                </S.TableRowCardCol2>
            </S.TableRow>

            <S.TableRow>
                <S.TableRowCardCol1>
                    <img src={`${PATH}/KH.png`} alt="KH" />
                </S.TableRowCardCol1>
                <S.TableRowCardCol2>
                    <p>
                        If a player plays a King, they can say "King calling on..." followed by a
                        card Rank. The next player must play that card.
                        <br />
                        <br />
                        If they can't play that card, they have to draw a card from the deck. This
                        continues until a player plays the card.
                        <br />
                        <br />
                        This trick card is optional and can be activated in the Settings.
                    </p>
                </S.TableRowCardCol2>
            </S.TableRow>
        </S.Table>

        <h1>Common Strategies</h1>

        <p>
            When deciding whether to stay in the Suit or match the Rank but switch Suits, always try
            to keep the current Suit matching whatever you have the most of.
        </p>

        <p>
            Use your trick cards to hamper the leader. If the player on your left is winning,
            skipping over them, making them draw cards or reversing play is a good tactic. If the
            player on your right is winning, you might save your trick cards until someone reverses
            play, so that you can attack that player instead.
        </p>

        <p>
            If a leading opponent has recently played a Jack and declared a Suit, and you can change
            the Suit before that opponent gets another turn, do so. The chances are that the player
            who played the Jack has named the Suit they need to get rid of their last few cards, so
            changing to a different Suit might help to foil their plans.
        </p>

        <p>
            Similarly, if a King has been activated as a trick card in the Settings, and a leading
            opponent has recently played a King and declared a Rank, and you can change the Rank
            before that opponent gets another turn, do so too. As previously, the chances are that
            the player who played the King has named the Rank they need to get rid of their last few
            cards.
        </p>

        <p>
            Try not to use your trick cards on your first turns. Save them for later in the game
            (specially Jacks). But at the same time, make sure to dump them before the end of the
            game, as you can't finish on a trick card, and you will have to draw a card from the
            deck.
        </p>
    </S.Content>
);

export default JackChangeItRules;
