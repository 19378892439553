import React from 'react';
import PropTypes from 'prop-types';

import * as S from './buttonStyles';

const Button = ({ text, disabled, ...props }) => (
    <S.Button disabled={disabled} {...props}>
        {text}
    </S.Button>
);

Button.propTypes = {
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Button;
