import React from 'react';
import PropTypes from 'prop-types';

import MobileMenuItem from './MobileMenuItem';
import X from './X';

import { useWindowDimensions } from '../../utils/hooks';

import * as S from './mobileMenuStyles';

const MobileMenu = ({ show, path, closeMenu }) => {
    const { width } = useWindowDimensions();

    return show && width < 890 ? (
        <S.MobileMenu id="mbl-mn">
            <S.MobileMenuList>
                <MobileMenuItem
                    text="All Games"
                    path="/"
                    active={path === 'home'}
                    onClick={closeMenu}
                />
                <MobileMenuItem
                    text="About Us"
                    path="/about"
                    active={path === 'about'}
                    onClick={closeMenu}
                />
                <MobileMenuItem
                    text="Game Rules"
                    path="/rules"
                    active={path === 'rules'}
                    onClick={closeMenu}
                />

                <S.HR />

                <MobileMenuItem
                    text="Contact Us"
                    path="/contact"
                    active={path === 'contact'}
                    onClick={closeMenu}
                />
                <MobileMenuItem
                    text="Privacy Policy"
                    path="/privacy"
                    active={path === 'privacy'}
                    onClick={closeMenu}
                />
            </S.MobileMenuList>

            <X onClick={closeMenu} />
        </S.MobileMenu>
    ) : null;
};

MobileMenu.propTypes = {
    show: PropTypes.bool.isRequired,
    path: PropTypes.string,
    closeMenu: PropTypes.func.isRequired,
};

export default MobileMenu;
