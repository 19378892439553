import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as S from './navBarStyles';

const NavBarItem = ({ text, path, active }) =>
    active ? (
        <S.NavBarItem active={active}>{text}</S.NavBarItem>
    ) : (
        <Link to={path}>
            <S.NavBarItem>{text}</S.NavBarItem>
        </Link>
    );

NavBarItem.propTypes = {
    text: PropTypes.string.isRequired,
    path: PropTypes.string,
    active: PropTypes.bool.isRequired,
};

export default NavBarItem;
