import * as React from 'react';
import PropTypes from 'prop-types';

import Seo from '../components/seo/Seo';
import Layout from './Layout';

import * as S from '../styles/pagesStyles';

const GameRules = ({ gameTitle, gameRules }) => (
    <Layout>
        <Seo title={`Rules of ${gameTitle}`} />

        <S.TitleLong>{`Rules of ${gameTitle}`}</S.TitleLong>
        <S.BottomBorder />

        <S.Content>{gameRules}</S.Content>
    </Layout>
);

GameRules.propTypes = {
    gameTitle: PropTypes.string.isRequired,
    gameRules: PropTypes.node.isRequired,
};

export default GameRules;
