import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import NavBar from '../nav-bar/NavBar';
import MenuIcon from './MenuIcon';
import MobileMenu from '../mobile-menu/MobileMenu';

import { getBasePath } from '../../utils/utils';
import { useWindowDimensions } from '../../utils/hooks';

import * as S from './headerStyles';

const logo = new Image();
logo.src = getBasePath() + 'static/img/logos/cards-logo-small.png';

const Header = ({ path }) => {
    const [showMenu, setShowMenu] = useState(false);

    const { width } = useWindowDimensions();

    useEffect(() => {
        if (showMenu && width > 890) setShowMenu(false);
    }, [showMenu, width]);

    return (
        <S.Header>
            <S.HeaderBox>
                <Link
                    to="/"
                    disabled={path === 'home'}
                    style={{
                        pointerEvents: path === 'home' ? 'none' : 'auto',
                    }}
                >
                    <S.Logo src={logo.src} alt="Cards Logo" />
                </Link>

                <S.Title>Proper Card Games</S.Title>

                <NavBar path={path} />

                <MenuIcon onClick={() => setShowMenu(true)} />
            </S.HeaderBox>

            <MobileMenu
                show={showMenu}
                path={path}
                closeMenu={() => setShowMenu(false)}
                //
            />
        </S.Header>
    );
};

Header.propTypes = {
    path: PropTypes.string,
};

export default Header;
