import styled, { css } from 'styled-components';

import screen from './mediaScreens';

export const HomeGrid = styled.div`
    display: flex;
    max-width: 100rem;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;

    @media ${screen.phoneLand} {
        margin-top: -2rem;
        margin-bottom: -2rem;
    }
`;

// ------------------------------------------

const sharedTitleStyles = css`
    font-family: var(--font-family-prosto);
    font-size: 3.25rem;
    font-weight: 400;
    margin: 3rem auto 2rem;
    padding-top: 1rem;
    text-align: center;

    @media ${screen.phonePort} {
        padding-top: 0;
        font-size: 3.5rem;
    }
`;

export const Title = styled.h1`
    ${sharedTitleStyles}

    width: 35rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid var(--color-button-beige);

    @media ${screen.phonePort} {
        width: 90%;
    }
`;

export const TitleLong = styled.h1`
    ${sharedTitleStyles}

    margin: 3rem 2rem -3.25rem;
`;

export const ErrorTitle = styled.h1`
    ${sharedTitleStyles}

    padding: 4rem 3rem 0;
`;

export const ErrorSubTitle = styled.p`
    font-size: 1.6rem;
    font-weight: 300;
    text-align: center;
    padding: 0 3rem;
`;

// ------------------------------------------

export const RulesItemBox = styled.div`
    margin-top: 4rem;
`;

export const QuestionBox = styled.section`
    margin-top: 5rem;
`;

const borderStyles = css`
    border-top: 1px solid var(--color-button-beige);
    width: 60%;
    max-width: 60rem;

    @media ${screen.tabletPort} {
        width: 90%;
    }
`;

export const BottomBorder = styled.div`
    ${borderStyles}

    margin: 4rem auto 2rem;
`;

export const HR = styled.div`
    ${borderStyles}

    margin: 5rem auto 0;
`;

// ------------------------------------------

export const Content = styled.section`
    max-width: 95rem;
    margin: 0 auto;

    @media ${screen.tabletPort} {
        margin: 0 2rem;
    }

    @media ${screen.phonePort} {
        margin: 0 1.5rem;
    }

    a {
        font-size: 1.8rem;
        color: var(--color-link-highlight);
        font-weight: 400;

        &:hover {
            border-bottom: 1px solid var(--color-button-beige);
        }

        @media ${screen.tabletPort} {
            font-size: 2rem;
        }

        @media ${screen.phonePort} {
            font-size: 2.5rem;
        }
    }

    h1,
    h2,
    h3 {
        font-family: var(--font-family-prosto);
        font-weight: 400;

        @media ${screen.phoneLand} {
            text-align: center;
        }
    }

    strong {
        text-transform: uppercase;
        font-weight: 400;
    }

    h1 {
        padding: 5rem 4rem 0 4rem;
        font-size: 2.5rem;
        border-bottom: 1px solid var(--color-button-beige);
        padding-bottom: 1rem;

        @media ${screen.phonePort} {
            font-size: 3rem;
            padding: 5rem 0 0;

            width: 90%;
            margin: 0 auto;
        }
    }

    h2 {
        padding: 4.5rem 4rem 0 4rem;
        font-size: 2.25rem;

        @media ${screen.phonePort} {
            font-size: 2.75rem;
        }
    }

    h3 {
        padding: 3.5rem 4rem 0 4rem;
        font-size: 2rem;

        @media ${screen.phonePort} {
            font-size: 2.5rem;
        }
    }

    h4 {
        padding: 4.5rem 4rem 0 4rem;
        font-size: 1.8rem;
        font-weight: 300;
        font-style: italic;

        @media ${screen.tabletPort} {
            font-size: 2rem;
        }

        @media ${screen.phonePort} {
            font-size: 2.5rem;
        }
    }

    p,
    ul,
    li {
        font-size: 1.8rem;
        font-weight: 300;

        @media ${screen.tabletPort} {
            font-size: 2rem;
        }

        @media ${screen.phonePort} {
            font-size: 2.5rem;
        }
    }

    p,
    ul {
        padding: 2.5rem 4rem 0.5rem 4rem;
    }

    ul {
        margin-left: 5rem;
        padding-top: 1.5rem;
        margin-bottom: -0.75rem;

        @media ${screen.phonePort} {
            margin-left: 2.5rem;
        }
    }

    li {
        padding-bottom: 1rem;

        p {
            padding: 1rem 2rem 0 0;
        }
    }

    h1,
    h2,
    h3,
    h4,
    p,
    ul {
        @media ${screen.phoneLand} {
            padding-left: 3rem;
            padding-right: 3rem;
        }

        @media ${screen.phonePort} {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }
    }
`;
