import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const Seo = ({ title, description }) => {
    const defaultTitle = 'Proper Card Games — Play Classic Card Games Online';
    const defaultDescription =
        'Play classic card games like Blackjack, Jack Change It and Crazy Eights for free. No sign-up or download necessary!';
    const url = 'https://www.propercardgames.com';

    const metaTitle = title === 'home' ? defaultTitle : `${title} | Proper Card Games`;
    const metaDescription = description || defaultDescription;

    const path = 'https://www.propercardgames.com/static/img';
    const metaImage = `${path}/meta_img.jpg`;

    const keywords = `free, card games, online card games, blackjack, jack change it, crazy eights, thousand, 1000, poker`;

    return (
        <Helmet>
            {/* Primary Meta Tags */}
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={keywords} />
            <link rel="canonical" href={url} />

            {/* OpenGraph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={metaImage} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content={metaDescription} />
            <meta name="twitter:image" content={metaImage} />

            <html lang="en" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />

            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={`${path}/icons/apple-touch-icon.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href={`${path}/icons/favicon-32x32.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={`${path}/icons/favicon-16x16.png`}
            />
            <link rel="manifest" href={`${path}/icons/site.webmanifest`} />
            <link rel="mask-icon" href={`${path}/icons/safari-pinned-tab.svg`} color="#5bbad5" />
            <link rel="shortcut icon" href={`${path}/icons/favicon.ico`} />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="msapplication-config" content={`${path}/icons/browserconfig.xml`} />
            <meta name="theme-color" content="#ffffff" />
        </Helmet>
    );
};

Seo.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
};

export default Seo;
