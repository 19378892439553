import React from 'react';
import { Link } from 'react-router-dom';

import Layout from '../templates/Layout';

import Seo from '../components/seo/Seo';

import * as S from '../styles/pagesStyles';

const PrivacyPage = () => (
    <Layout path="privacy">
        <Seo title="Privacy Policy" />

        <S.Title>Privacy Policy</S.Title>

        <S.Content>
            <p>Last updated: 27th September, 2020</p>
            <p>
                This Privacy Policy describes Our policies and procedures on the collection, use and
                disclosure of Your information when You use the Service and tells You about Your
                privacy rights and how the law protects You.
            </p>
            <p>
                We use Your Personal data to provide and improve the Service. By using the Service,
                You agree to the collection and use of information in accordance with this Privacy
                Policy.
            </p>
            <h1>Interpretation and Definitions</h1>
            <h2>Interpretation</h2>
            <p>
                The words of which the initial letter is capitalised have meanings defined under the
                following conditions. The following definitions shall have the same meaning
                regardless of whether they appear in singular or in plural.
            </p>
            <h2>Definitions</h2>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
                <li>
                    <strong>Account</strong> means a unique account created for You to access our
                    Service or parts of our Service.
                </li>
                <li>
                    <strong>Company</strong> (referred to as either "the Company", "We", "Us" or
                    "Our" in this Agreement) refers to Proper Card Games.
                </li>
                <li>
                    <strong>Cookies</strong> are small files that are placed on Your computer,
                    mobile device or any other device by a website, containing the details of Your
                    browsing history on that website among its many uses.
                </li>
                <li>
                    <strong>Country</strong> refers to: United Kingdom
                </li>
                <li>
                    <strong>Device</strong> means any device that can access the Service such as a
                    computer, a mobile phone or a digital tablet.
                </li>
                <li>
                    <strong>Personal Data</strong> is any information that relates to an identified
                    or identifiable individual.
                </li>
                <li>
                    <strong>Service</strong> refers to the Website.
                </li>
                <li>
                    <strong>Service Provider</strong> means any natural or legal person who
                    processes the data on behalf of the Company. It refers to third-party companies
                    or individuals employed by the Company to facilitate the Service, to provide the
                    Service on behalf of the Company, to perform services related to the Service or
                    to assist the Company in analyzing how the Service is used.
                </li>
                <li>
                    <strong>Third-party Social Media Service</strong> refers to any website or any
                    social network website through which a User can log in or create an account to
                    use the Service.
                </li>
                <li>
                    <strong>Usage Data</strong> refers to data collected automatically, either
                    generated by the use of the Service or from the Service infrastructure itself
                    (for example, the duration of a page visit).
                </li>
                <li>
                    <strong>Website</strong> refers to Proper Card Games, accessible from
                    <Link to="/"> www.propercardgames.com</Link>
                </li>
                <li>
                    <strong>You</strong> means the individual accessing or using the Service, or the
                    company, or other legal entity on behalf of which such individual is accessing
                    or using the Service, as applicable.
                </li>
            </ul>

            <h1>Collecting and Using Your Personal Data</h1>
            <h2>Types of Data Collected</h2>
            <h3>Personal Data</h3>
            <p>
                While using Our Service, We may ask You to provide Us with certain personally
                identifiable information that can be used to contact or identify You. Personally
                identifiable information may include, but is not limited to:
            </p>
            <ul>
                <li>Email address</li>
                <li>First name and last name</li>
            </ul>
            <h2>Use of Your Personal Data</h2>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
                <li>
                    <strong>To provide and maintain our Service</strong>, including to monitor the
                    usage of our Service.
                </li>
                <li>
                    <strong>To manage Your requests:</strong> To attend and manage Your requests to
                    Us.
                </li>
                <li>
                    <strong>For business transfers:</strong> We may use Your information to evaluate
                    or conduct a merger, divestiture, restructuring, reorganisation, dissolution, or
                    other sale or transfer of some or all of Our assets, whether as a going concern
                    or as part of bankruptcy, liquidation, or similar proceeding, in which Personal
                    Data held by Us about our Service users is among the assets transferred.
                </li>
                <li>
                    <strong>For other purposes</strong>: We may use Your information for other
                    purposes, such as data analysis, identifying usage trends, determining the
                    effectiveness of our promotional campaigns and to evaluate and improve our
                    Service, products, services, marketing and your experience.
                </li>
            </ul>
            <p>We may share Your personal information in the following situations:</p>
            <ul>
                <li>
                    <strong>With Service Providers:</strong> We may share Your personal information
                    with Service Providers to monitor and analyse the use of our Service, to contact
                    You.
                </li>
                <li>
                    <strong>For business transfers:</strong> We may share or transfer Your personal
                    information in connection with, or during negotiations of, any merger, sale of
                    Company assets, financing, or acquisition of all or a portion of Our business to
                    another company.
                </li>
                <li>
                    <strong>With Affiliates:</strong> We may share Your information with Our
                    affiliates, in which case we will require those affiliates to honor this Privacy
                    Policy. Affiliates include Our parent company and any other subsidiaries, joint
                    venture partners or other companies that We control or that are under common
                    control with Us.
                </li>
                <li>
                    <strong>With business partners:</strong> We may share Your information with Our
                    business partners to offer You certain products, services or promotions.
                </li>
                <li>
                    <strong>With Your consent</strong>: We may disclose Your personal information
                    for any other purpose with Your consent.
                </li>
            </ul>
            <h2>Retention of Your Personal Data</h2>
            <p>
                The Company will retain Your Personal Data only for as long as is necessary for the
                purposes set out in this Privacy Policy. We will retain and use Your Personal Data
                to the extent necessary to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws), resolve disputes, and
                enforce our legal agreements and policies.
            </p>
            <p>
                The Company will also retain Usage Data for internal analysis purposes. Usage Data
                is generally retained for a shorter period of time, except when this data is used to
                strengthen the security or to improve the functionality of Our Service, or We are
                legally obligated to retain this data for longer time periods.
            </p>
            <h2>Transfer of Your Personal Data</h2>
            <p>
                Your information, including Personal Data, is processed at the Company's operating
                offices and in any other places where the parties involved in the processing are
                located. It means that this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or other governmental
                jurisdiction where the data protection laws may differ than those from Your
                jurisdiction.
            </p>
            <p>
                Your consent to this Privacy Policy followed by Your submission of such information
                represents Your agreement to that transfer.
            </p>
            <p>
                The Company will take all steps reasonably necessary to ensure that Your data is
                treated securely and in accordance with this Privacy Policy and no transfer of Your
                Personal Data will take place to an organisation or a country unless there are
                adequate controls in place including the security of Your data and other personal
                information.
            </p>
            <h2>Disclosure of Your Personal Data</h2>
            <h3>Business Transactions</h3>
            <p>
                If the Company is involved in a merger, acquisition or asset sale, Your Personal
                Data may be transferred. We will provide notice before Your Personal Data is
                transferred and becomes subject to a different Privacy Policy.
            </p>
            <h3>Law enforcement</h3>
            <p>
                Under certain circumstances, the Company may be required to disclose Your Personal
                Data if required to do so by law or in response to valid requests by public
                authorities (e.g. a court or a government agency).
            </p>
            <h3>Other legal requirements</h3>
            <p>
                The Company may disclose Your Personal Data in the good faith belief that such
                action is necessary to:
            </p>
            <ul>
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of the Company</li>
                <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>Protect the personal safety of Users of the Service or the public</li>
                <li>Protect against legal liability</li>
            </ul>
            <h2>Security of Your Personal Data</h2>
            <p>
                The security of Your Personal Data is important to Us, but remember that no method
                of transmission over the Internet, or method of electronic storage is 100% secure.
                While We strive to use commercially acceptable means to protect Your Personal Data,
                We cannot guarantee its absolute security.
            </p>

            <h1>Your California Privacy Rights (California's Shine the Light law)</h1>
            <p>
                Under California Civil Code Section 1798 (California's Shine the Light law),
                California residents with an established business relationship with us can request
                information once a year about sharing their Personal Data with third parties for the
                third parties' direct marketing purposes.
            </p>
            <p>
                If you'd like to request more information under the California Shine the Light law,
                and if You are a California resident, You can contact Us using the contact
                information provided below.
            </p>

            <h1>
                California Privacy Rights for Minor Users (California Business and Professions Code
                Section 22581)
            </h1>
            <p>
                California Business and Professions Code section 22581 allow California residents
                under the age of 18 who are registered users of online sites, services or
                applications to request and obtain removal of content or information they have
                publicly posted.
            </p>
            <p>
                To request removal of such data, and if You are a California resident, You can
                contact Us using the contact information provided below, and include the email
                address associated with Your account.
            </p>
            <p>
                Be aware that Your request does not guarantee complete or comprehensive removal of
                content or information posted online and that the law may not permit or require
                removal in certain circumstances.
            </p>

            <h1>Links to Other Websites</h1>
            <p>
                Our Service may contain links to other websites that are not operated by Us. If You
                click on a third party link, You will be directed to that third party's site. We
                strongly advise You to review the Privacy Policy of every site You visit.
            </p>
            <p>
                We have no control over and assume no responsibility for the content, privacy
                policies or practices of any third party sites or services.
            </p>

            <h1>Changes to this Privacy Policy</h1>
            <p>
                We may update Our Privacy Policy from time to time and update the "Last updated"
                date at the top of this Privacy Policy.
            </p>
            <p>
                You are advised to review this Privacy Policy periodically for any changes. Changes
                to this Privacy Policy are effective when they are posted on this page.
            </p>

            <h1>Contact Us</h1>
            <p>
                If you have any questions about this Privacy Policy, You can contact us by visiting
                our
                <Link to="/contact"> contact </Link>page.
            </p>
        </S.Content>
    </Layout>
);

export default PrivacyPage;
