import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import RulesPage from './pages/RulesPage';
import FaqPage from './pages/FaqPage';
import ContactPage from './pages/ContactPage';
import PrivacyPage from './pages/PrivacyPage';
// import NotFoundPage from './pages/NotFoundPage.jsx';

import AllGameRulesPages from './pages/rules/AllGameRulesPages';

// import { generateUniqueId, getBasePath } from './utils/utils';
import ScrollToTop from './utils/ScrollToTop';

// import GAMES from './data/games';

const App = () => (
    <Fragment>
        <ScrollToTop>
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route path="/about" component={AboutPage} />
                <Route exact path="/rules" component={RulesPage} />
                <Route path="/faq" component={FaqPage} />

                <Route path="/contact" component={ContactPage} />
                <Route path="/privacy" component={PrivacyPage} />

                <AllGameRulesPages />

                {/* Outside Links to the Games */}
                {/* {GAMES.map(game => {
                    const gameName = game.name.replace('-soon', '');
                    return (
                        <Route
                            key={generateUniqueId()}
                            path={`/${gameName}`}
                            component={() => {
                                const path = window.location.pathname.replace(`/${gameName}/`, '');
                                // 'path' is a possible join-multiplayer-game-link
                                // that needs to be passed on

                                window.location.href = `${getBasePath()}/${gameName}/${path}`;
                                return null;
                            }}
                        />
                    );
                })} */}

                {/* <Route component={NotFoundPage} /> */}
            </Switch>
        </ScrollToTop>
    </Fragment>
);

export default App;
