import styled from 'styled-components';

import screen from '../../styles/mediaScreens';

// ------------------------------------------

export const Content = styled.div`
    h1 {
        width: 36rem;
        margin: 0 auto;
        text-align: center;

        @media ${screen.phonePort} {
            margin-top: -1rem;
        }
    }

    img {
        height: 14rem;
    }
`;

// ------------------------------------------

export const Table = styled.section`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    border-top: 1px solid var(--color-white-tinted);
    border-bottom: 1px solid var(--color-white-tinted);

    margin: 3rem 10rem 0;

    @media ${screen.tabletPort} {
        width: 90vw;
        margin: 4rem auto 1rem;
    }

    @media ${screen.phonePort} {
        margin: 3rem auto;
    }

    p {
        padding: 0 0 0 2rem;
    }
`;

export const TableRow = styled.div`
    display: flex;

    width: 70rem;
    margin: 0 auto;

    flex-direction: column;
    padding: 2rem 1rem;

    @media ${screen.tabletPort} {
        width: 80vw;
    }

    @media ${screen.phonePort} {
        padding: 2rem 0;
    }

    &:not(:last-child) {
        border-bottom: 1px solid var(--color-white-tinted);
    }
`;

// ------------------------------------------
// CARDS TABLE

export const TableRowCardCol1 = styled.div`
    width: 100%;
    text-align: center;

    p {
        padding: 0 0 2rem 0;
    }

    img {
        margin: 0.75rem auto 1.75rem;

        @media ${screen.phonePort} {
            height: 15rem;
        }
    }
`;

export const TableRowCardCol2 = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 auto 0.75rem;

    @media ${screen.phonePort} {
        width: 60vw;
        display: flex;
        justify-content: space-around;

        p {
            margin-left: -4.5rem;
            margin-right: -4rem;
        }
    }

    .on-top {
        margin-left: -7.5rem;
    }

    .card-next {
        margin-right: 1rem;

        @media ${screen.phonePort5c} {
            margin-right: 0.5rem;
        }
    }
`;

// ------------------------------------------
// BUTTONS TABLE

export const TableRowButtonCol1 = styled.div`
    align-self: center;

    @media ${screen.phoneLand} {
        width: 100%;
        text-align: center;
    }

    button {
        padding: 0.3rem;
        background-color: var(--color-button-beige);
        background: linear-gradient(to bottom, #eae0c2 5%, #ccc2a6 100%);
        border: none;
        font-family: inherit;
        font-weight: 400;
        font-size: 1.5rem;
        text-decoration: none;
        margin: 2rem auto;
        color: #151515;
        height: 5rem;
        width: 14rem;
        border-radius: 50px;
        text-transform: uppercase;

        @media ${screen.phonePort} {
            width: 15rem;
            height: 6rem;
            font-size: 1.75rem;
        }
    }
`;
export const TableRowButtonCol2 = styled.div`
    padding: 1.5rem 0;

    align-self: center;

    @media ${screen.phonePort} {
        width: 60vw;
        display: flex;
        justify-content: space-around;
        margin: 0 auto;

        p {
            margin-left: -4.5rem;
            margin-right: -4rem;
        }
    }
`;
