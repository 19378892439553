const FAQ = [
    {
        question: 'How can I change my name?',
        answer: 'You can easily change your name by going to Settings.',
    },
    {
        question: 'What else can I customize?',
        answer:
            "You can change the image of the Deck, as well as the color of the Game Table. Each game also has a set of their own customizable options which allow you to add/remove certain game's features.",
    },
    {
        question: 'Can I play against other people?',
        answer:
            "Most of our games have a Multiplayer mode where you can play with other people. If a game has this option available, you'll see a Human Head icon next to Bot icon when it first starts.",
    },
    {
        question: "I can't see any public multiplayer games to join.",
        answer:
            'If there are no open games available, you can create a new game and invite your friends to play with you.',
    },
    {
        question: "What's the difference between 'Private Game' and 'Public Game'?",
        answer:
            "When creating a Multiplayer game you have an option to set your game's status to 'Private' or 'Public'. A Private game can only be discovered by the people who have received your invite link. People without an invite link won't be able to find your game.",
    },
    {
        question: "What is 'Approve Players'?",
        answer:
            "If 'Approve Players' is selected when you're creating a Multiplayer game, you will have to approve pending players in order for them to join your game. If this option is disabled, players will be able to join without your approval.",
    },
    {
        question: 'How many players can play at the same time?',
        answer: 'There are maximum 4 available spaces in each multiplayer game.',
    },
    {
        question: "Why I can't change game settings in multiplayer mode.",
        answer:
            'Only a Game Creator can change game settings in multiplayer mode. Any changes will be applied on the next game when the cards are dealt again.',
    },
    {
        question: 'If I lose Internet connection, will I have to start everything again?',
        answer:
            "If you're playing Singleplayer game, you have 12 hours to reconnect to the game once your Internet comes back. If you're playing Multiplayer game, you have only 1 minute to reconnect, otherwise the game will carry on without you.",
    },
    {
        question: 'What happens if a Game Creator leaves the game?',
        answer:
            'If a Game Creator leaves the game and there are at least 2 players left, the game will carry on without a Game Creator. Otherwise, the remaining player will have to join another game or create a new one.',
    },
    {
        question: 'Can you please create [INSERT GAME NAME HERE]?',
        answer:
            "If enough people request a game and we feel that it is a good fit, then we'll definitely create it. Send us a message through the contact page requesting a specific game, and we'll add your request to our list.",
    },
    {
        question: 'I have some strategy tips for how you can make the bots better.',
        answer:
            "If you have any suggestions on how we can improve any of our games, please send us a message through the contact page, and we'll implement any necessary changes where appropriate.",
    },
    {
        question: 'Other questions?',
        answer:
            "If you have a question that wasn't answered on this page, please send us a message though the contact page and a member of our team will be able to help you.",
    },
];

export default FAQ;
