import React from 'react';

import { getBasePath } from '../../utils/utils.js';

import * as S from './rulesStyles';

const PATH = getBasePath() + 'static/img/cards';

const BlackjackRules = () => (
    <S.Content>
        <p>
            Blackjack (also known as Twenty-One) is one of the most popular online card games, and
            probably the most popular casino game other than slots. It is a comparing-type card
            game, where players compete against the dealer.
        </p>

        <h1>Game Objective</h1>

        <p>The purpose of the game is to beat the dealer's hand without going over 21.</p>

        <h1>Setup</h1>
        <p>
            It is usually played with either a single, double, four or six standard decks of 52
            cards. A smaller number of decks is beneficial to the player because they have a higher
            probability to draw a Blackjack (two cards that have a total value of 21). The more
            decks are used, the more player's odds drop.
        </p>

        <p>
            Prior to the deal of the cards, the player must make a bet by placing chips in their
            respective betting boxes. Once the bet is placed, the player and the dealer will receive
            two cards.
        </p>

        <p>
            One of the dealer's cards (known as the dealer's upcard) is always dealt face up, so
            that the player can see its value. The other dealer's card (known as the dealer's
            downcard) is dealt face down.
        </p>

        <p>Both player's cards are dealt face up.</p>

        <h1>Card Values</h1>
        <p>
            When playing Blackjack, the numeral cards 2 to 10 have their face values, Jacks, Queens
            and Kings are valued at 10, and Aces can have a value of either 1 or 11. The Ace is
            always valued at 11 unless that would result in the hand going over 21, in which case it
            is valued as 1. Card suits have no meaning here.
        </p>

        <S.Table>
            <S.TableRow>
                <S.TableRowCardCol1>
                    <p>Count as 10</p>
                </S.TableRowCardCol1>
                <S.TableRowCardCol2>
                    <img className="card-next" src={`${PATH}/JS.png`} alt="JS" />
                    <img className="card-next" src={`${PATH}/QH.png`} alt="QH" />
                    <img src={`${PATH}/KC.png`} alt="KC" />
                </S.TableRowCardCol2>
            </S.TableRow>

            <S.TableRow>
                <S.TableRowCardCol1>
                    <p>1 or 11</p>
                </S.TableRowCardCol1>
                <S.TableRowCardCol2>
                    <img src={`${PATH}/AH.png`} alt="A♥" />
                </S.TableRowCardCol2>
            </S.TableRow>

            <S.TableRow>
                <S.TableRowCardCol1>
                    <p>Face Value</p>
                </S.TableRowCardCol1>
                <S.TableRowCardCol2>
                    <img src={`${PATH}/2S.png`} alt="2S" />
                    <img className="on-top" src={`${PATH}/4S.png`} alt="4S" />
                    <img className="on-top" src={`${PATH}/3S.png`} alt="3S" />
                    <img className="on-top" src={`${PATH}/5S.png`} alt="5S" />
                    <img className="on-top" src={`${PATH}/6S.png`} alt="6S" />
                    <img className="on-top" src={`${PATH}/7S.png`} alt="7S" />
                    <img className="on-top" src={`${PATH}/8S.png`} alt="8S" />
                    <img className="on-top" src={`${PATH}/9S.png`} alt="9S" />
                    <img className="on-top" src={`${PATH}/0S.png`} alt="0S" />
                </S.TableRowCardCol2>
            </S.TableRow>
        </S.Table>

        <p>
            Any hand with an Ace valued as 11 is called a "soft" hand. All other hands are "hard"
            hands.
        </p>

        <p>
            A starting hand of a 10-valued-card and an Ace is called a "Blackjack" which beats all
            hands other than another Blackjack. If both, the player and the dealer, have Blackjack,
            the result is a "push" (or a tie): neither the player nor the house wins, and the bet is
            returned to the player.
        </p>

        <h1>Game Play</h1>

        <p>
            After the player looks at their initial two cards and sees the value of one of the
            dealer's two cards, they must make a playing decision.
        </p>

        <p>This includes the following playing options:</p>

        <S.Table>
            <S.TableRow>
                <S.TableRowButtonCol1>
                    <button>Hit</button>
                </S.TableRowButtonCol1>
                <S.TableRowButtonCol2>
                    <p>
                        If the player wishes to take another card, they can "hit", and a single card
                        is played face up onto their hand. If the hand's total is less than 21, the
                        player can choose to "hit" again or "stand". If the total is 21, the hand
                        automatically stands. If the total is over 21, the hand is "bust", and the
                        player's bet is taken by the house.
                    </p>
                </S.TableRowButtonCol2>
            </S.TableRow>

            <S.TableRow>
                <S.TableRowButtonCol1>
                    <button>Stand</button>
                </S.TableRowButtonCol1>
                <S.TableRowButtonCol2>
                    <p>
                        If the player is happy with the total they've been dealt, they can "stand",
                        taking no further cards. The dealer will now reveal their down card and then
                        continue to take cards until they have a total of 17 or higher.
                    </p>
                </S.TableRowButtonCol2>
            </S.TableRow>

            <S.TableRow>
                <S.TableRowButtonCol1>
                    <button>Double</button>
                </S.TableRowButtonCol1>
                <S.TableRowButtonCol2>
                    <p>
                        If the player has a total of 9, 10 or 11, they can choose to "double down".
                        To do this, they place a second bet equal to their first beside their first
                        bet. A player who doubles down receives exactly one more card face up and is
                        then forced to stand regardless of the total. This option is only available
                        on the player's two-card starting hand.
                    </p>
                </S.TableRowButtonCol2>
            </S.TableRow>

            <S.TableRow>
                <S.TableRowButtonCol1>
                    <button>Split</button>
                </S.TableRowButtonCol1>
                <S.TableRowButtonCol2>
                    <p>
                        If the player's first two cards are of matching rank (i.e. two Jacks, two
                        Queens etc.), they can choose to place an additional bet equal to their
                        original bet and split the cards into two hands. When a pair is "split", the
                        player is playing each card as a separate hand and they can draw as many
                        cards as they like to each hand, except split aces. After splitting aces,
                        each hand gets only one additional card.
                    </p>
                </S.TableRowButtonCol2>
            </S.TableRow>

            <S.TableRow>
                <S.TableRowButtonCol1>
                    <button>Insurance</button>
                </S.TableRowButtonCol1>
                <S.TableRowButtonCol2>
                    <p>
                        If the dealer has an Ace face up, the player is offered the option to place
                        an "Insurance" bet. Insurance is a side bet on whether or not the dealer has
                        a Blackjack, unrelated to the final outcome of the round. If the player
                        chooses to take Insurance, they place an additional bet equal to half of
                        their original bet. This Insurance bet wins if the dealer has Blackjack, and
                        is paid out at 2:1.
                    </p>
                </S.TableRowButtonCol2>
            </S.TableRow>
        </S.Table>

        <p>
            If the dealer shows a 10-valued-card as their face-up card, they might have a Blackjack.
            Therefore, they check their face-down card to see if a Blackjack is present. If the
            dealer does have a Blackjack, it is immediately revealed. If the player also has a
            Blackjack - it's a push. The hand is not played out further.
        </p>

        <p>
            If the dealer does not have a Blackjack, play continues as normal with the first player
            to the left of the dealer.
        </p>

        <p>
            After the player has completed their actions, the dealer plays their hand according to
            fixed rules. First, they will reveal their down card. The dealer will then continue to
            take cards until they have a total of 17 or higher.
        </p>

        <p>If the dealer busts, all non-busted player's hands are automatically winners.</p>

        <p>The cards are automatically reshuffled once the 60% of the deck has been dealt.</p>

        <h1>Payouts</h1>

        <p>
            If the player and the dealer have equal unbusted totals the hand is considered a push
            (tie), and the player's bet is returned.
        </p>

        <p>
            If the player wins a hand, they are paid out at 1:1 on the total bet wagered on that
            hand. So if the player wagered $10 and won, they would be paid a total of $20 (their $10
            bet back and $10 winnings).
        </p>

        <p>
            If the player has a Blackjack they are paid at 3:2, so that a wager of $10 would be paid
            a total of $25 (their $10 bet back plus $15 winnings).
        </p>

        <p>
            An Ace-Ten combination formed after splitting a pair of Aces or 10-valued-cards is not a
            Blackjack and simply pays as any other hand with a count of 21.
        </p>

        <p>
            If the player has placed an Insurance bet and the dealer has a Blackjack, the player's
            hand loses but the Insurance bet is paid out at 2:1. So if the player had bet $10 on the
            hand and $5 on the Insurance bet, they would lose the $10 and be paid a total of $15 –
            their $5 Insurance bet returned and $10 winnings. This effectively results in a push
            overall for the hand.
        </p>

        <h1>Common Strategies</h1>

        <p>
            As a general rule, the player should never take Insurance. Unless using an advanced and
            mathematically proven strategy that will alert the player to the rare situations in
            which Insurance is worthwhile, it should be avoided as a bad bet for the player.
        </p>

        <p>
            Winning tactics in Blackjack require that the player play each hand in an optimum way,
            and such strategy always takes into account what the dealer's upcard is:
        </p>

        <ul>
            <li>
                When the dealer's upcard is a good one (7, 8, 9, 10-card, or Ace), the player should
                not stop drawing until a total of 17 or more is reached.
            </li>

            <li>
                When the dealer's upcard is a poor one (4, 5, or 6), the player should stop drawing
                as soon as they get a total of 12 or higher. The strategy here is never to take a
                card if there is any chance of going bust. The desire with this poor holding is to
                let the dealer hit and hopefully go over 21.
            </li>

            <li>
                When the dealer's up card is a fair one (2 or 3), the player should stop with a
                total of 13 or higher.
            </li>

            <li>
                With a soft hand, the general strategy is to keep hitting until a total of at least
                18 is reached. Thus, with an Ace and a 6 (7 or 17), the player would not stop at 17,
                but would hit.
            </li>
        </ul>

        <p>The basic strategy for doubling down is as follows:</p>

        <ul>
            <li>With a total of 11, the player should always double down.</li>

            <li>
                With a total of 10, he should double down unless the dealer shows a 10-valued-card
                or an Ace.
            </li>

            <li>
                With a total of 9, the player should double down only if the dealer's card is fair
                or poor (2 through 6).
            </li>
        </ul>

        <p>Strategy for Splitting:</p>

        <ul>
            <li>The player should always split a pair of Aces or 8s.</li>

            <li>
                Identical 10-valued-cards should not be split, and neither should a pair of 5s,
                since two 5s are a total of 10, which can be used more effectively in doubling down.
            </li>

            <li>
                A pair of 4s should not be split either, as a total of 8 is a good number to draw
                to.
            </li>

            <li>
                Generally, 2s, 3s, or 7s can be split unless the dealer has an 8, 9, 10-valued-card,
                or an Ace.
            </li>

            <li>Finally, 6s should not be split unless the dealer's card is poor (2 through 6).</li>
        </ul>
    </S.Content>
);

export default BlackjackRules;
