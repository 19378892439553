export function getBasePath() {
    const w = window.location;
    return w.host === 'localhost:3000' ? 'http://127.0.0.1:8000/' : `${w.protocol}//${w.host}/`;
}

export function scrollToTop() {
    window.scrollTo(0, 0);
    document.getElementById('scrl-btn').style.display = 'none';
}

export function generateUniqueId(length = 10) {
    const result = [];
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * characters.length)));
    }
    return result.join('');
}
