import styled, { css } from 'styled-components';

import screen from '../../styles/mediaScreens';

// ------------------------------------------

const hoverStyles = ({ comingSoon }) => {
    if (!comingSoon)
        return css`
            transform: scale(1.01);
        `;
};

export const Figure = styled.figure`
    width: 28rem;
    height: 15.4rem;
    margin: 1.5rem;

    box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    @media ${screen.phoneLand} {
        width: 32rem;
        height: 17.6rem;
    }

    @media ${screen.phonePort} {
        width: 28rem;
        height: 15.4rem;
    }

    @media ${screen.phonePort5c} {
        width: 24.5rem;
        height: 13.48rem;
    }

    img {
        width: 28rem;
        height: 15.4rem;
        border: 1px solid var(--color-header-footer-border);
        border-radius: 10px;

        @media ${screen.phoneLand} {
            width: 32rem;
            height: 17.6rem;
        }

        @media ${screen.phonePort} {
            width: 28rem;
            height: 15.4rem;
        }

        @media ${screen.phonePort5c} {
            width: 24.5rem;
            height: 13.48rem;
        }

        &:hover {
            ${hoverStyles}

            @media ${screen.phoneLandOnly} {
                transform: scale(1);
            }

            @media ${screen.phonePort} {
                transform: scale(1);
            }
        }
    }
`;

// ------------------------------------------

export const RulesItem = styled.p`
    font-family: var(--font-family-prosto);
    color: var(--color-white-text);
    font-size: 2.25rem;
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;

    padding-bottom: 0.5rem;
    border-bottom: 1px solid transparent;

    &:hover {
        cursor: pointer;
        border-bottom: 1px solid var(--color-button-beige);
    }
`;

export const LinkBox = styled.div`
    width: 25rem;
    margin: 0 auto;
`;

// ------------------------------------------

export const BulletPoint = styled.div`
    font-size: 1.8rem;
    color: var(--color-button-beige);
`;

export const FaqItemBox = styled.div`
    display: flex;
    padding-left: 6rem;
    padding-right: 6rem;
    margin-bottom: 0.5rem;

    @media ${screen.tabletPort} {
        padding-left: 3.25rem;
        padding-right: 3rem;
    }

    @media ${screen.phonePort} {
        margin-left: -1rem;
    }
`;

export const Question = styled.div`
    font-size: 1.8rem;
    font-family: var(--font-family-prosto);
    margin-left: 3rem;
    margin-bottom: 0.125rem;
    color: var(--color-white-text);

    @media ${screen.tabletPort} {
        font-size: 2rem;
        margin-left: 1.5rem;
    }

    @media ${screen.phonePort} {
        font-size: 2.5rem;
    }
`;

export const Answer = styled.div`
    font-weight: 300;
    font-size: 1.8rem;
    padding-left: 10.5rem;
    padding-right: 6rem;
    margin-top: 1rem;
    margin-bottom: 3.5rem;

    @media ${screen.tabletPort} {
        font-size: 2rem;
        padding-left: 6.25rem;
        padding-right: 2rem;
    }

    @media ${screen.phonePort} {
        padding-left: 5.25rem;
        font-size: 2.5rem;
    }
`;
