import React from 'react';

import CrazyEightsRules from '../../components/game-rules/CrazyEightsRules';
import GameRules from '../../templates/GameRules';

const CrazyEightsRulesPage = () => (
    <GameRules
        gameTitle="Crazy Eights"
        gameRules={<CrazyEightsRules />}
        //
    />
);

export default CrazyEightsRulesPage;
