import styled, { css } from 'styled-components';

import screen from './mediaScreens';

const containerStyles = ({ type }) => {
    if (type === 'home')
        return css`
            margin: 0 auto;
            max-width: 100rem;
            position: relative;
            padding: 3rem;

            font-size: 1.6rem;
            align-items: center;

            min-height: calc(100vh - 16rem);

            @media ${screen.tabletPort} {
                min-height: calc(100vh - 14rem);
            }

            @media ${screen.phoneLand} {
                min-height: calc(100vh - 12rem);
            }

            @media ${screen.phonePort} {
                min-height: calc(100vh - 11rem);
            }
        `;
    else
        return css`
            margin-bottom: 6rem;
            min-height: calc(100vh - 25rem);

            @media ${screen.tabletPort} {
                min-height: calc(100vh - 23rem);
            }

            @media ${screen.phoneLand} {
                min-height: calc(100vh - 21rem);
            }

            @media ${screen.phonePort} {
                min-height: calc(100vh - 20rem);
            }
        `;
};

export const Container = styled.section`
    ${containerStyles}
`;
