import React from 'react';
import PropTypes from 'prop-types';

import FooterMenuItem from './FooterMenuItem';

import * as S from './footerStyles';

const Footer = ({ path }) => (
    <S.Footer>
        <S.FooterBox>
            <S.NavBar>
                <FooterMenuItem text="Home" path="/" active={path === 'home'} />
                <S.Separator>|</S.Separator>

                <FooterMenuItem text="About" path="/about" active={path === 'about'} />
                <S.Separator>|</S.Separator>

                <FooterMenuItem text="Contact" path="/contact" active={path === 'contact'} />
                <S.Separator>|</S.Separator>

                <FooterMenuItem text="Privacy" path="/privacy" active={path === 'privacy'} />
            </S.NavBar>

            <S.Copyright>
                <span>&copy; 2020 - {new Date().getFullYear()} Proper Card Games</span>
            </S.Copyright>
        </S.FooterBox>
    </S.Footer>
);

Footer.propTypes = {
    path: PropTypes.string,
};

export default Footer;
