import { useState, useEffect, useCallback } from 'react';

const $ = document.getElementById.bind(document);

// ------------------------------------------

export function useEventListener(eventName, handler, element = document) {
    useEffect(() => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;

        const eventListener = event => handler(event);

        element.addEventListener(eventName, eventListener);

        return () => element.removeEventListener(eventName, eventListener);
    }, [eventName, handler, element]);
}

// ------------------------------------------

export function useOnScrollListener() {
    useEffect(() => {
        window.onscroll = () => {
            const scrollBtnVisible = $('scrl-btn').style.display !== 'none';

            if (document.documentElement.scrollTop > 800) {
                if (!scrollBtnVisible) {
                    $('scrl-btn').style.display = 'flex';

                    $('scrl-btn').animate(
                        {
                            bottom: ['-8rem', '7rem'],
                        },
                        {
                            duration: 600,
                            easing: 'ease-out',
                            iterations: 1,
                        }
                    );
                }
            } else {
                if (scrollBtnVisible) $('scrl-btn').style.display = 'none';
            }
        };
    });
}

// ------------------------------------------

export function useWindowDimensions() {
    const hasWindow = typeof window !== 'undefined';

    const getWindowDimensions = useCallback(() => {
        const width = hasWindow ? window.innerWidth : null;
        const height = hasWindow ? window.innerHeight : null;
        return { width, height };
    }, [hasWindow]);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        if (hasWindow) {
            const handleResize = () => setWindowDimensions(getWindowDimensions());

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow, getWindowDimensions]);
    return windowDimensions;
}
