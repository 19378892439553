import styled from 'styled-components';

export const Button = styled.button`
    padding: 0.3rem;
    background-color: var(--color-button-beige);
    background: linear-gradient(to bottom, #eae0c2 5%, #ccc2a6 100%);
    border-radius: 3px;
    border: none;
    font-family: inherit;
    font-weight: 400;
    font-size: 1.5rem;
    text-decoration: none;
    transition: all 0.2s;

    width: 10rem;
    height: 3.5rem;
    margin: 2rem auto;
    text-transform: uppercase;

    opacity: ${props => (props.disabled ? 0.8 : 1)};
    color: ${props => (props.disabled ? 'var(--color-disabled-text)' : '#151515')};

    &:hover {
        cursor: ${props => !props.disabled && 'pointer'};
        transform: ${props => !props.disabled && 'translateY(0.5px)'};

        background: ${props =>
            !props.disabled && 'linear-gradient(to bottom, #eae0c2 5%, #bdb08a 100%)'};
    }

    &:focus {
        outline: none;
    }
`;
