import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as S from './mobileMenuStyles';

const MobileMenuItem = ({ text, path, active, onClick }) =>
    active ? (
        <S.MobileMenuItem active={active}>{text}</S.MobileMenuItem>
    ) : (
        <Link to={path}>
            <S.MobileMenuItem onClick={onClick}>{text}</S.MobileMenuItem>
        </Link>
    );

MobileMenuItem.propTypes = {
    text: PropTypes.string.isRequired,
    path: PropTypes.string,
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default MobileMenuItem;
