const screen = {
    phonePortFold: '(max-width: 17.5em)',
    phonePort5c: '(max-width: 21em)',
    phonePort: '(max-width: 28.13em)',
    //
    phoneLand: '(max-width: 37.5em)',
    phoneLandOnlyFold: '(max-width: 700px) and (max-height: 17.5em)',
    phoneLandOnly5c: '(max-width: 37.5em) and (max-height: 40em)',
    phoneLandOnly: '(max-width: 55.62em) and (max-height: 30em)',
    //
    w500px: '(max-width: 31.25em)',
    w700px: '(max-width: 43.75em)',
    w800px: '(max-width: 50em)',
    //
    tabletPort: '(max-width: 55.62em)',
    minWidth: '(max-width: 65.37em)',
    tabletLand: '(max-width: 75em)',
    //
    standard: '(min-width: 90em)',
    bigHeight: '(min-height: 109em)',
    bigDesktop: '(min-width: 90em) and (min-height: 52em)',
};

export default screen;
