import React from 'react';

import Layout from '../templates/Layout';

import Seo from '../components/seo/Seo';
import GameThumbnail from '../components/pages-list-items/GameThumbnail';

import { generateUniqueId } from '../utils/utils';

import * as S from '../styles/pagesStyles';

import GAMES from '../data/games';

const HomePage = () => (
    <Layout path="home">
        <Seo title="home" />

        <S.HomeGrid>
            {GAMES.map(game => (
                <GameThumbnail
                    key={generateUniqueId()}
                    gameName={game.name}
                    comingSoon={game.name.endsWith('-soon')}
                />
            ))}
        </S.HomeGrid>
    </Layout>
);

export default HomePage;
