import styled, { css } from 'styled-components';

import screen from '../../styles/mediaScreens';

const animations = css`
    @keyframes mobile-menu-appears {
        from {
            width: 40vw;
        }

        to {
            width: 80vw;
        }
    }

    @keyframes mobile-menu-list-appears {
        0% {
            opacity: 0;
        }

        15% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
`;

export const MobileMenu = styled.div`
    @media ${screen.tabletPort} {
        top: 5.5rem;
        right: -1rem;
        box-shadow: var(--shadow-main);

        flex-direction: column;
        z-index: 100;

        background-image: linear-gradient(
            to right bottom,
            var(--color-header-mid-blue),
            var(--color-header-light-blue)
        );

        transition: width 0.8s;

        margin-left: -6.25rem;

        padding: 1.5rem 0.5rem;
        line-height: 1.8;

        position: fixed;
        height: 120vh;
        width: 80vw;
        top: 0;
        border: none;

        ${animations}

        -webkit-animation: 'mobile-menu-appears' 0.8s 1; /* Safari 4+ */
        -moz-animation: 'mobile-menu-appears' 0.8s 1; /* Fx 5+ */
        -o-animation: 'mobile-menu-appears' 0.8s 1; /* Opera 12+ */
        animation: 'mobile-menu-appears' 0.8s 1; /* IE 10+, Fx 29+ */
    }

    @media ${screen.phoneLandOnly} {
        line-height: 1.7;
        top: -2rem;
    }

    @media ${screen.phoneLandOnlyFold} {
        line-height: 1.4;
    }
`;

export const MobileMenuList = styled.div`
    @media ${screen.tabletPort} {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-right: -4vw;
        margin-top: 22vh;

        -webkit-animation: 'mobile-menu-list-appears' 1s 1; /* Safari 4+ */
        -moz-animation: 'mobile-menu-list-appears' 1s 1; /* Fx 5+ */
        -o-animation: 'mobile-menu-list-appears' 1s 1; /* Opera 12+ */
        animation: 'mobile-menu-list-appears' 1s 1; /* IE 10+, Fx 29+ */
    }

    @media ${screen.phoneLandOnly} {
        margin-right: -5vw;
        line-height: 1.2;
    }

    @media ${screen.phonePort} {
        margin-right: 0vw;
    }
`;

const activeInactiveStyles = ({ active }) => {
    if (active)
        return css`
            color: var(--color-link-highlight);

            &:hover {
                cursor: default;
            }
        `;
    else
        return css`
            &:hover {
                cursor: pointer;
                color: var(--color-link-highlight);
            }
        `;
};

export const MobileMenuItem = styled.p`
    @media ${screen.tabletPort} {
        font-size: 2.5rem;
        font-family: var(--font-family-prosto);
        margin-bottom: 1.5rem;

        ${activeInactiveStyles}
    }

    @media ${screen.phoneLand} {
        font-size: 2.25rem;
    }

    @media ${screen.phonePort} {
        font-size: 2.25rem;
    }
`;

export const HR = styled.div`
    @media ${screen.tabletPort} {
        opacity: 0.6;
        border-top: 1px solid var(--color-white-tinted-dark);
        margin: 1.5rem auto 2.25rem;
        width: 60%;
    }

    @media ${screen.phoneLandOnly} {
        margin: 1rem auto 2rem;
    }

    @media ${screen.phonePort} {
        width: 75%;
    }
`;

export const Icon = styled.svg`
    @media ${screen.tabletPort} {
        position: absolute;
        height: 4rem;
        width: 4rem;
        fill: whitesmoke;
        opacity: 0.8;

        top: 3rem;
        right: 4rem;

        -webkit-animation: 'mobile-menu-list-appears' 0.8s 1; /* Safari 4+ */
        -moz-animation: 'mobile-menu-list-appears' 0.8s 1; /* Fx 5+ */
        -o-animation: 'mobile-menu-list-appears' 0.8s 1; /* Opera 12+ */
        animation: 'mobile-menu-list-appears' 0.8s 1; /* IE 10+, Fx 29+ */
    }

    @media ${screen.phoneLandOnly} {
        top: 6rem;
    }

    &:hover {
        fill: var(--color-link-highlight);
        cursor: pointer;
    }
`;
