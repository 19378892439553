import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as S from './pagesListItemStyles';

const RulesListItem = ({ gameTitle, gameName }) => (
    <S.LinkBox>
        <Link to={`/rules/${gameName}`}>
            <S.RulesItem>{gameTitle}</S.RulesItem>
        </Link>
    </S.LinkBox>
);

RulesListItem.propTypes = {
    gameTitle: PropTypes.string.isRequired,
    gameName: PropTypes.string.isRequired,
};

export default RulesListItem;
