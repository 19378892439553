import React from 'react';

import { getBasePath, scrollToTop } from '../../utils/utils';
import { useOnScrollListener } from '../../utils/hooks';

import * as S from './scrollUpButtonStyles';

const ScrollUpButton = () => {
    useOnScrollListener();

    return (
        <S.Button
            id="scrl-btn"
            alt="scroll up"
            src={`${getBasePath()}static/img/icons/up-btn.png`}
            style={{ display: 'none' }}
            onClick={scrollToTop}
        />
    );
};

export default ScrollUpButton;
