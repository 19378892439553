import styled from 'styled-components';

import screen from '../../styles/mediaScreens';

export const Button = styled.img`
    display: none;
    height: 7rem;
    position: fixed;

    bottom: 7rem;
    right: 15rem;

    z-index: 99;

    height: 5rem;
    width: 5rem;
    background-color: #adb4bc;
    padding: 0.85rem 0.75rem 0.75rem;
    border-radius: 50px;

    &:hover {
        cursor: pointer;

        filter: brightness(90%);
        -webkit-filter: brightness(90%);
        -moz-filter: brightness(90%);
        -o-filter: brightness(90%);
        -ms-filter: brightness(90%);
    }

    @media ${screen.bigDesktop} {
        right: 30rem;
    }

    @media ${screen.tabletLand} {
        right: 10rem;
    }

    @media ${screen.tabletPort} {
        right: 6rem;
    }

    @media ${screen.phonePort} {
        right: 2rem;
    }
`;
