import React from 'react';

import { getBasePath } from '../../utils/utils.js';

import * as S from './rulesStyles';

const PATH = getBasePath() + 'static/img/cards';

const CrazyEightsRules = () => (
    <S.Content>
        <p>
            Crazy Eights is a simple shedding-type game for two or more players. The game is similar
            to Switch or Jack Change It. The name Crazy Eights dates to the 1940s, derived from the
            military designation for discharge of mentally unstable soldiers, Section 8.
        </p>

        <h1>Game Objective</h1>

        <p>The purpose of the game is to discard all of your cards before your opponents.</p>

        <h1>Setup</h1>
        <p>
            Using a standard deck of 52 cards, five cards are dealt to each player (or seven in a
            two-player game). The remaining cards form the "deck", which players will use to draw
            their new cards. The top card is turned face up beside the deck to form the "pile".
        </p>

        <h1>Game Play</h1>
        <p>
            The player to the left of the dealer starts first, choosing a card that matches either
            the Suit or the Rank of the card on the pile. If the player cannot play a card on this
            turn, they draw a card from the deck, and then the turn passes to the left.
        </p>

        <p>
            When there are no more cards left in the deck, the top pile card is removed and placed
            to the side. The remaining cards are reshuffled to form a new deck, and the game
            resumes.
        </p>

        <h1>Special Cards</h1>
        <p>
            Several Ranks of cards have specific effects when they are played, and these are
            normally referred to as "special cards".
        </p>

        <S.Table>
            <S.TableRow>
                <S.TableRowCardCol1>
                    <img src={`${PATH}/8D.png`} alt="8D" />
                </S.TableRowCardCol1>
                <S.TableRowCardCol2>
                    <p>
                        An 8 can be played on any card, and the player of the 8 must nominate a
                        Suit. The next player is required to play a card of that Suit or switch
                        Suits with another 8.
                        <br />
                        <br />
                        If they can't play that card, they have to draw a card from the deck. This
                        continues until the card is played.
                    </p>
                </S.TableRowCardCol2>
            </S.TableRow>

            <S.TableRow>
                <S.TableRowCardCol1>
                    <img src={`${PATH}/2C.png`} alt="2C" />
                </S.TableRowCardCol1>
                <S.TableRowCardCol2>
                    <p>
                        When a 2 is played, the next player must draw two cards from the deck (an 8
                        cannot be played in this case). However, if they possess a 2 themselves,
                        they may play it instead, and the next player after them must draw four
                        cards (two for each 2 played).
                        <br />
                        <br />
                        This can continue until all the 2s are played, and a player has to draw
                        eight cards.
                    </p>
                </S.TableRowCardCol2>
            </S.TableRow>

            <S.TableRow>
                <S.TableRowCardCol1>
                    <img src={`${PATH}/AH.png`} alt="A♥" />
                </S.TableRowCardCol1>
                <S.TableRowCardCol2>
                    <p>
                        When an Ace is played, the order of play is reversed. The player to the
                        right will take the next turn, and play continues in this fashion until
                        another Ace is played.
                        <br />
                        <br />
                        In a two-player game, the Ace's effect is ignored as play's order cannot be
                        changed.
                    </p>
                </S.TableRowCardCol2>
            </S.TableRow>

            <S.TableRow>
                <S.TableRowCardCol1>
                    <img src={`${PATH}/QC.png`} alt="QC" />
                </S.TableRowCardCol1>
                <S.TableRowCardCol2>
                    <p>
                        When a Queen is played, the player whose turn it is next misses their turn,
                        and play passes to the next player.
                        <br />
                        <br />
                        In a two-player game, after a Queen is played, the player can play another
                        card with a matching Suit or Rank straight away.
                    </p>
                </S.TableRowCardCol2>
            </S.TableRow>
        </S.Table>

        <h1>Common Strategies</h1>

        <p>
            When deciding whether to stay in the Suit or match the Rank but switch Suits, always try
            to keep the current Suit matching whatever you have the most of.
        </p>

        <p>
            Use your special cards to hamper the leader. If the player on your left is winning,
            skipping over them, making them draw cards or reversing play is a good tactic. If the
            player on your right is winning, you might save your special cards until someone
            reverses play, so that you can attack that player instead.
        </p>

        <p>
            If a leading opponent has recently played an 8 and declared a Suit, and you can change
            the Suit before that opponent gets another turn, do so. The chances are good that the
            player who played the 8 has named the Suit they need to get rid of their last few cards,
            so changing to a different Suit might help to foil their plans.
        </p>

        <p>
            Don't save your 8s until the last card if playing with specials. If you aren't
            guaranteed a turn every cycle around the table, consider playing your 8s once someone
            gets down to three cards. Otherwise, you may be holding an 8 when your turn is skipped,
            and the game ends.
        </p>
    </S.Content>
);

export default CrazyEightsRules;
