import React from 'react';

import JackChangeItRules from '../../components/game-rules/JackChangeItRules';
import GameRules from '../../templates/GameRules';

const JackChangeItRulesPage = () => (
    <GameRules
        gameTitle="Jack Change It"
        gameRules={<JackChangeItRules />}
        //
    />
);

export default JackChangeItRulesPage;
