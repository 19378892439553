import styled from 'styled-components';

import screen from '../../styles/mediaScreens';

export const ContactForm = styled.form`
    height: 55rem;
    width: 50rem;
    margin: 2rem auto;

    @media ${screen.tabletPort} {
        padding-top: 0.5rem;
        padding-bottom: 3.5rem;
        margin: 3rem auto 2rem;
    }

    @media ${screen.phoneLand} {
        height: 52rem;
    }

    @media ${screen.w500px} {
        width: 85%;
    }

    @media ${screen.phoneLandOnly} {
        height: 40rem;
    }

    @media ${screen.phonePort} {
        height: 50rem;
    }

    label {
        font-size: 1.6rem;
        margin-top: 1rem;

        @media ${screen.tabletPort} {
            font-size: 1.8rem;
        }

        span {
            color: var(--color-link-highlight);
        }
    }

    input {
        width: 100%;
        height: 4rem;
        padding: 0 1rem;
        font-size: 1.8rem;
        background-color: var(--color-textfield);
        font-family: 'Lato', Geneva, Verdana, sans-serif;

        border: none;
        border-radius: 3px;

        &:not(:last-child) {
            margin-right: 1rem;
        }

        @media ${screen.tabletPort} {
            font-size: 1.9rem;
        }
    }

    textarea {
        background-color: var(--color-textfield);
        font-family: 'Lato', Geneva, Verdana, sans-serif;

        width: 100%;
        min-width: 100%;
        min-height: 25rem;
        max-height: 105rem;
        height: 25rem;
        padding: 1rem;
        font-size: 1.8rem;
        resize: none;
        border-radius: 3px;

        @media ${screen.tabletPort} {
            font-size: 1.9rem;
        }

        @media ${screen.phoneLandOnly} {
            min-height: 15rem;
            height: 15rem;
        }
    }
`;

export const Row = styled.div`
    display: flex;
    padding: 0.5rem;

    @media ${screen.tabletPort} {
        width: 100%;
    }

    @media ${screen.phoneLand} {
        padding: 0.25rem;
    }

    @media ${screen.phoneLandOnly} {
        padding: 0.125rem;
    }
`;

export const RowCol = styled.div`
    display: flex;
    width: 50%;

    &:not(:last-child) {
        margin-right: 1rem;
    }
`;

export const ErrorMsg = styled.div`
    text-align: center;
    font-size: 1.5rem;
    color: #f0be76;
    margin-bottom: 0rem;

    @media ${screen.phonePort} {
        display: flex;
        justify-content: center;
    }
`;

export const Message = styled.p`
    font-family: var(--font-family-prosto);
    color: var(--color-link-highlight);
    margin-top: 5rem;
    text-align: center;
`;
