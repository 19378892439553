import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import ScrollUpButton from '../components/scroll-up-button/ScrollUpButton';

import * as S from '../styles/layoutStyles';

const Layout = ({ path, children }) => (
    <Fragment>
        <Header path={path} />

        <S.Container type={path}>{children}</S.Container>

        <ScrollUpButton />

        <Footer path={path} />
    </Fragment>
);

Layout.propTypes = {
    path: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Layout;
