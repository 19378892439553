import React from 'react';

import Layout from '../templates/Layout';

import Seo from '../components/seo/Seo';
import RulesListItem from '../components/pages-list-items/RulesListItem';

import { generateUniqueId } from '../utils/utils';

import * as S from '../styles/pagesStyles';

import GAMES from '../data/games';

const RulesPage = () => (
    <Layout path="rules">
        <Seo title="Game Rules" />

        <S.Title>Game Rules</S.Title>

        <S.RulesItemBox>
            {GAMES.map(game => (
                <RulesListItem
                    key={generateUniqueId()}
                    gameTitle={game.title}
                    gameName={game.name.replace('-soon', '')}
                />
            ))}
        </S.RulesItemBox>
    </Layout>
);

export default RulesPage;
